<template>
  <Toast />
  <Dialog
    v-model:visible="mostrarModal"
    :style="{ width: '450px' }"
    :header="'Pieza Seleccionada: ' + piezaSeleccionada"
    :modal="true"
    class="p-fluid"
    @hide="ocultarDialog"
  >
    <div class="col-12">
      <div class="p-fluid formgrid grid">
        <div class="field col-12 md:col-12">
          <span><strong>SELECCIONE UN MÉDICO:</strong></span>
          <Dropdown
            v-model="medicoSelected"
            :options="listado_medicos"
            optionLabel="nombre_completo"
            optionValue="id"
            placeholder="SELECCIONE UN MÉDICO"
            :filter="true"
            v-tooltip.top="'Seleccione un médico'"
          />
        </div>
        <div class="field col-12 md:col-12">
          <span><strong>SELECCIONE UN TRATAMIENTO:</strong></span>
          <div class="p-inputgroup mt-2">
            <AutoComplete
              :disabled="medicoSelected === null"
              v-model="productoSelected"
              :suggestions="productos"
              field="descripcion"
              v-tooltip.top="'Buscar Tratamiento'"
              @keyup.enter="buscarProducto"
            />
            <Button
              :disabled="productoSelected === null || productoSelected === ''"
              icon="pi pi-search"
              class="p-button-warning"
              v-tooltip.top="'Buscar Tratamiento'"
              @click="buscarProducto"
            />
          </div>
        </div>
        <div class="field col-12 md:col-12">
          <span><strong>SELECCIONE UBICACIÓN:</strong></span>
          <Dropdown
            v-model="ubicacionDentalSelected"
            :options="data_ubicacion_dental"
            optionLabel="nombre"
            optionValue="id"
            placeholder="SELECCIONE UBICACIÓN"
            :filter="true"
            v-tooltip.top="'Seleccione Ubicación Dental'"
          />
        </div>
        <div class="field col-12 md:col-12">
          <span><strong>NOTAS/OBSERVACIONES</strong></span>
          <Textarea
            v-model="nota"
            autoResize
            :rows="3"
            placeholder="Ingrese una nota"
            v-tooltip.top="'Ingrese una nota'"
          />
        </div>
      </div>
      <div class="p-inputgroup flex justify-content-end">
        <Button
          label="CANCELAR"
          icon="pi pi-times"
          class="p-button-lg p-button-danger mr-2"
          @click="ocultarDialog"
        />
        <Button
          label="GUARDAR"
          icon="pi pi-save"
          class="p-button-lg p-button-info"
          @click="guardarTratamiento"
        />
      </div>
    </div>
  </Dialog>
</template>
<script>
import ProductService from "@/service/ProductService";
export default {
  emits: ["closeModal", "agregarTratamiento"],
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    piezaSeleccionada: {
      type: Number,
      default: 0,
    },
    medicos: {
      type: Array,
      default: () => [],
    },
    cliente_id: {
      type: Number,
      default: 0,
    },
    ubicacion_dental: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      mostrarModal: this.show,
      listado_medicos: this.medicos,
      medicoSelected: null,
      productoSelected: null,
      productos: [],
      nota: "",
      data_ubicacion_dental: this.ubicacion_dental,
      ubicacionDentalSelected: null,
      data_odonto_cliente_id: this.cliente_id,
    };
  },
  productService: null,
  created() {
    this.productService = new ProductService();
  },
  methods: {
    validacionesFormulario() {
      if (this.productoSelected === null) {
        this.$toast.add({
          severity: "error",
          summary: "ERROR",
          detail: "Debe seleccionar un tratamiento",
          life: 600,
        });
        return false;
      }
      if (this.medicoSelected === null) {
        this.$toast.add({
          severity: "error",
          summary: "ERROR",
          detail: "Debe seleccionar un médico",
          life: 600,
        });
        return false;
      }
      if (this.ubicacionDentalSelected === null) {
        this.$toast.add({
          severity: "error",
          summary: "ERROR",
          detail: "Debe seleccionar una cara",
          life: 600,
        });
        return false;
      }
      return true;
    },
    guardarTratamiento() {
      if (!this.validacionesFormulario()) return;
      let datos = {
        cliente_id: this.cliente_id,
        medico_id: this.medicoSelected,
        nombre_medico:
          this.listado_medicos.find(
            (medico) => medico.id === this.medicoSelected
          ).nombre_completo || "",
        dientes: this.piezaSeleccionada,
        producto_id: this.productoSelected.id,
        tratamiento: this.productoSelected.descripcion,
        costo: this.productoSelected.precio_sugerido,
        cara: this.ubicacionDentalSelected,
        observaciones: this.nota,
      };
      this.$emit("agregarTratamiento", datos);
      this.productoSelected = null;
      this.nota = "";
      this.ocultarDialog();
    },
    buscarProducto() {
      setTimeout(() => {
        let datos = {
          texto: this.productoSelected,
        };
        this.productService
          .buscar_servicio(datos)
          .then((response) => {
            if (response.status == 400 || response.productos.length == 0) {
              this.$toast.add({
                severity: "error",
                summary: "ERROR",
                detail: "No se encontró el producto",
                life: 300,
              });
              return;
            }
            this.productos = [...response.productos];
          })
          .catch((error) => console.log(error));
      }, 200);
    },
    ocultarDialog() {
      this.$emit("closeModal");
    },
  },
  watch: {
    show(val) {
      this.mostrarModal = val;
    },
    medicos(val) {
      this.listado_medicos = val;
    },
    cliente_id(val) {
      this.data_odonto_cliente_id = val;
    },
    ubicacion_dental(val) {
      this.data_ubicacion_dental = val;
    },
  },
};
</script>
