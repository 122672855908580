<template>
  <div class="surface-section px-4 py-5 md:px-12 lg:px-12">
    <div
      class="flex align-items-center flex-column lg:justify-content-center lg:flex-row"
    >
      <h4><strong>ODONTOGRAMA</strong></h4>
    </div>
    <!-- <div class="col-12">
      <div class="p-fluid formgrid grid"> -->
    <div class="field col-12 md:col-12 text-center">
      <span><strong>INFORMACIÓN DE ODONTOGRAMA:</strong></span>
      <!-- Indicaciones de marcación -->
      <span class="badge badge-danger">ROJO = CARIES</span>
      <span class="badge badge-info">AZUL = RESTAURACIÓN</span>
      <span class="badge badge-danger">X = PARA EXTRAER</span>
      <span class="badge badge-info">X = PARA TRATAMIENTO</span>
    </div>

    <div class="col-12">
      <div class="p-fluid formgrid grid">
        <div class="field col-12 md:col-12 text-center">
          <table class="table" style="width: 100%">
            <!-- Fila 1 -->
            <tr>
              <td>
                <img
                  :src="'https://tiluchisoft.com/clinicadental/dientes/fila1/18C.png'"
                  alt="Descripción de la imagen"
                  @click="nuevoTratamiento(18)"
                  v-tooltip.top="'Pieza 18C'"
                />
              </td>
              <td>
                <img
                  :src="'https://tiluchisoft.com/clinicadental/dientes/fila1/17C.png'"
                  alt="Descripción de la imagen"
                  @click="nuevoTratamiento(17)"
                  v-tooltip.top="'Pieza 17C'"
                />
              </td>
              <td>
                <img
                  :src="'https://tiluchisoft.com/clinicadental/dientes/fila1/16C.png'"
                  alt="Descripción de la imagen"
                  @click="nuevoTratamiento(16)"
                  v-tooltip.top="'Pieza 16C'"
                />
              </td>
              <td>
                <img
                  :src="'https://tiluchisoft.com/clinicadental/dientes/fila1/15C.png'"
                  alt="Descripción de la imagen"
                  @click="nuevoTratamiento(15)"
                  v-tooltip.top="'Pieza 15C'"
                />
              </td>
              <td>
                <img
                  :src="'https://tiluchisoft.com/clinicadental/dientes/fila1/14C.png'"
                  alt="Descripción de la imagen"
                  @click="nuevoTratamiento(14)"
                  v-tooltip.top="'Pieza 14C'"
                />
              </td>
              <td>
                <img
                  :src="'https://tiluchisoft.com/clinicadental/dientes/fila1/13C.png'"
                  alt="Descripción de la imagen"
                  @click="nuevoTratamiento(13)"
                  v-tooltip.top="'Pieza 13C'"
                />
              </td>
              <td>
                <img
                  :src="'https://tiluchisoft.com/clinicadental/dientes/fila1/12C.png'"
                  alt="Descripción de la imagen"
                  @click="nuevoTratamiento(12)"
                  v-tooltip.top="'Pieza 12C'"
                />
              </td>
              <td>
                <img
                  :src="'https://tiluchisoft.com/clinicadental/dientes/fila1/11C.png'"
                  alt="Descripción de la imagen"
                  @click="nuevoTratamiento(11)"
                  v-tooltip.top="'Pieza 11C'"
                />
              </td>
              <td>
                <img
                  :src="'https://tiluchisoft.com/clinicadental/dientes/fila1/21C.png'"
                  alt="Descripción de la imagen"
                  @click="nuevoTratamiento(21)"
                  v-tooltip.top="'Pieza 21C'"
                />
              </td>
              <td>
                <img
                  :src="'https://tiluchisoft.com/clinicadental/dientes/fila1/22C.png'"
                  alt="Descripción de la imagen"
                  @click="nuevoTratamiento(22)"
                  v-tooltip.top="'Pieza 22C'"
                />
              </td>
              <td>
                <img
                  :src="'https://tiluchisoft.com/clinicadental/dientes/fila1/23C.png'"
                  alt="Descripción de la imagen"
                  @click="nuevoTratamiento(23)"
                  v-tooltip.top="'Pieza 23C'"
                />
              </td>
              <td>
                <img
                  :src="'https://tiluchisoft.com/clinicadental/dientes/fila1/24C.png'"
                  alt="Descripción de la imagen"
                  @click="nuevoTratamiento(24)"
                  v-tooltip.top="'Pieza 24C'"
                />
              </td>
              <td>
                <img
                  :src="'https://tiluchisoft.com/clinicadental/dientes/fila1/25C.png'"
                  alt="Descripción de la imagen"
                  @click="nuevoTratamiento(25)"
                  v-tooltip.top="'Pieza 25C'"
                />
              </td>
              <td>
                <img
                  :src="'https://tiluchisoft.com/clinicadental/dientes/fila1/26C.png'"
                  alt="Descripción de la imagen"
                  @click="nuevoTratamiento(26)"
                  v-tooltip.top="'Pieza 26C'"
                />
              </td>
              <td>
                <img
                  :src="'https://tiluchisoft.com/clinicadental/dientes/fila1/27C.png'"
                  alt="Descripción de la imagen"
                  @click="nuevoTratamiento(27)"
                  v-tooltip.top="'Pieza 27C'"
                />
              </td>
              <td>
                <img
                  :src="'https://tiluchisoft.com/clinicadental/dientes/fila1/28C.png'"
                  alt="Descripción de la imagen"
                  @click="nuevoTratamiento(28)"
                  v-tooltip.top="'Pieza 28C'"
                />
              </td>
            </tr>
            <!-- Línea Separadora -->
            <tr>
              <td colspan="16">
                <hr style="border: 0.5px solid #dcdcdc" />
              </td>
            </tr>
            <!-- Fila 2 -->
            <tr>
              <td>
                <img
                  :src="'https://tiluchisoft.com/clinicadental/dientes/fila2/18B.png'"
                  alt="Descripción de la imagen"
                  @click="nuevoTratamiento(18)"
                  v-tooltip.top="'Pieza 18B'"
                />
              </td>
              <td>
                <img
                  :src="'https://tiluchisoft.com/clinicadental/dientes/fila2/17B.png'"
                  alt="Descripción de la imagen"
                  @click="nuevoTratamiento(17)"
                  v-tooltip.top="'Pieza 17B'"
                />
              </td>
              <td>
                <img
                  :src="'https://tiluchisoft.com/clinicadental/dientes/fila2/16B.png'"
                  alt="Descripción de la imagen"
                  @click="nuevoTratamiento(16)"
                  v-tooltip.top="'Pieza 16B'"
                />
              </td>
              <td>
                <img
                  :src="'https://tiluchisoft.com/clinicadental/dientes/fila2/15B.png'"
                  alt="Descripción de la imagen"
                  @click="nuevoTratamiento(15)"
                  v-tooltip.top="'Pieza 15B'"
                />
              </td>
              <td>
                <img
                  :src="'https://tiluchisoft.com/clinicadental/dientes/fila2/14B.png'"
                  alt="Descripción de la imagen"
                  @click="nuevoTratamiento(14)"
                  v-tooltip.top="'Pieza 14B'"
                />
              </td>
            </tr>
            <!-- Línea Separadora -->
            <tr>
              <td colspan="16">
                <hr style="border: 0.5px solid #dcdcdc" />
              </td>
            </tr>
            <!-- Fila 3 -->
            <tr>
              <td>
                <img
                  :src="'https://tiluchisoft.com/clinicadental/dientes/fila3/18A.png'"
                  alt="Descripción de la imagen"
                  width="45"
                  height="150"
                  @click="nuevoTratamiento(18)"
                  v-tooltip.top="'Pieza 18A'"
                />
              </td>
              <td>
                <img
                  :src="'https://tiluchisoft.com/clinicadental/dientes/fila3/17A.png'"
                  alt="Descripción de la imagen"
                  width="45"
                  height="150"
                  @click="nuevoTratamiento(17)"
                  v-tooltip.top="'Pieza 17A'"
                />
              </td>
              <td>
                <img
                  :src="'https://tiluchisoft.com/clinicadental/dientes/fila3/16A.png'"
                  alt="Descripción de la imagen"
                  width="45"
                  height="150"
                  @click="nuevoTratamiento(16)"
                  v-tooltip.top="'Pieza 16A'"
                />
              </td>
              <td>
                <img
                  :src="'https://tiluchisoft.com/clinicadental/dientes/fila3/15A.png'"
                  alt="Descripción de la imagen"
                  width="45"
                  height="150"
                  @click="nuevoTratamiento(15)"
                  v-tooltip.top="'Pieza 15A'"
                />
              </td>
              <td>
                <img
                  :src="'https://tiluchisoft.com/clinicadental/dientes/fila3/14A.png'"
                  alt="Descripción de la imagen"
                  width="45"
                  height="150"
                  @click="nuevoTratamiento(14)"
                  v-tooltip.top="'Pieza 14A'"
                />
              </td>
              <td>
                <img
                  :src="'https://tiluchisoft.com/clinicadental/dientes/fila3/13A.png'"
                  alt="Descripción de la imagen"
                  width="45"
                  height="150"
                  @click="nuevoTratamiento(13)"
                  v-tooltip.top="'Pieza 13A'"
                />
              </td>
              <td>
                <img
                  :src="'https://tiluchisoft.com/clinicadental/dientes/fila3/12A.png'"
                  alt="Descripción de la imagen"
                  width="45"
                  height="150"
                  @click="nuevoTratamiento(12)"
                  v-tooltip.top="'Pieza 12A'"
                />
              </td>
              <td>
                <img
                  :src="'https://tiluchisoft.com/clinicadental/dientes/fila3/11A.png'"
                  alt="Descripción de la imagen"
                  width="45"
                  height="150"
                  @click="nuevoTratamiento(11)"
                  v-tooltip.top="'Pieza 11A'"
                />
              </td>
              <td>
                <img
                  :src="'https://tiluchisoft.com/clinicadental/dientes/fila3/21A.png'"
                  alt="Descripción de la imagen"
                  width="45"
                  height="150"
                  @click="nuevoTratamiento(21)"
                  v-tooltip.top="'Pieza 21A'"
                />
              </td>
              <td>
                <img
                  :src="'https://tiluchisoft.com/clinicadental/dientes/fila3/22A.png'"
                  alt="Descripción de la imagen"
                  width="45"
                  height="150"
                  @click="nuevoTratamiento(22)"
                  v-tooltip.top="'Pieza 22A'"
                />
              </td>
              <td>
                <img
                  :src="'https://tiluchisoft.com/clinicadental/dientes/fila3/23A.png'"
                  alt="Descripción de la imagen"
                  width="45"
                  height="150"
                  @click="nuevoTratamiento(23)"
                  v-tooltip.top="'Pieza 23A'"
                />
              </td>
              <td>
                <img
                  :src="'https://tiluchisoft.com/clinicadental/dientes/fila3/24A.png'"
                  alt="Descripción de la imagen"
                  width="45"
                  height="150"
                  @click="nuevoTratamiento(24)"
                  v-tooltip.top="'Pieza 24A'"
                />
              </td>
              <td>
                <img
                  :src="'https://tiluchisoft.com/clinicadental/dientes/fila3/25A.png'"
                  alt="Descripción de la imagen"
                  width="45"
                  height="150"
                  @click="nuevoTratamiento(25)"
                  v-tooltip.top="'Pieza 25A'"
                />
              </td>
              <td>
                <img
                  :src="'https://tiluchisoft.com/clinicadental/dientes/fila3/26A.png'"
                  alt="Descripción de la imagen"
                  width="45"
                  height="150"
                  @click="nuevoTratamiento(26)"
                  v-tooltip.top="'Pieza 26A'"
                />
              </td>
              <td>
                <img
                  :src="'https://tiluchisoft.com/clinicadental/dientes/fila3/27A.png'"
                  alt="Descripción de la imagen"
                  width="45"
                  height="150"
                  @click="nuevoTratamiento(27)"
                  v-tooltip.top="'Pieza 27A'"
                />
              </td>
              <td>
                <img
                  :src="'https://tiluchisoft.com/clinicadental/dientes/fila3/28A.png'"
                  alt="Descripción de la imagen"
                  width="45"
                  height="150"
                  @click="nuevoTratamiento(28)"
                  v-tooltip.top="'Pieza 28A'"
                />
              </td>
            </tr>
            <!-- Nro 18-28 -->
            <tr>
              <td style="font-size: 14px; font-weight: bold">18</td>
              <td style="font-size: 14px; font-weight: bold">17</td>
              <td style="font-size: 14px; font-weight: bold">16</td>
              <td style="font-size: 14px; font-weight: bold">15</td>
              <td style="font-size: 14px; font-weight: bold">14</td>
              <td style="font-size: 14px; font-weight: bold">13</td>
              <td style="font-size: 14px; font-weight: bold">12</td>
              <td style="font-size: 14px; font-weight: bold">11</td>
              <td style="font-size: 14px; font-weight: bold">21</td>
              <td style="font-size: 14px; font-weight: bold">22</td>
              <td style="font-size: 14px; font-weight: bold">23</td>
              <td style="font-size: 14px; font-weight: bold">24</td>
              <td style="font-size: 14px; font-weight: bold">25</td>
              <td style="font-size: 14px; font-weight: bold">26</td>
              <td style="font-size: 14px; font-weight: bold">27</td>
              <td style="font-size: 14px; font-weight: bold">28</td>
            </tr>
            <!-- Línea Separadora -->
            <tr>
              <td colspan="16">
                <hr style="border: 1px solid #003a6e" />
              </td>
            </tr>
            <!-- Nro 49 - 38 -->
            <tr>
              <td style="font-size: 14px; font-weight: bold">48</td>
              <td style="font-size: 14px; font-weight: bold">47</td>
              <td style="font-size: 14px; font-weight: bold">46</td>
              <td style="font-size: 14px; font-weight: bold">45</td>
              <td style="font-size: 14px; font-weight: bold">44</td>
              <td style="font-size: 14px; font-weight: bold">43</td>
              <td style="font-size: 14px; font-weight: bold">42</td>
              <td style="font-size: 14px; font-weight: bold">41</td>
              <td style="font-size: 14px; font-weight: bold">31</td>
              <td style="font-size: 14px; font-weight: bold">32</td>
              <td style="font-size: 14px; font-weight: bold">33</td>
              <td style="font-size: 14px; font-weight: bold">34</td>
              <td style="font-size: 14px; font-weight: bold">35</td>
              <td style="font-size: 14px; font-weight: bold">36</td>
              <td style="font-size: 14px; font-weight: bold">37</td>
              <td style="font-size: 14px; font-weight: bold">38</td>
            </tr>
            <!-- Fila 4 -->
            <tr>
              <td>
                <img
                  :src="'https://tiluchisoft.com/clinicadental/dientes/fila4/48A.png'"
                  alt="Descripción de la imagen"
                  width="45"
                  height="150"
                  @click="nuevoTratamiento(48)"
                  v-tooltip.top="'Pieza 48A'"
                />
              </td>
              <td>
                <img
                  :src="'https://tiluchisoft.com/clinicadental/dientes/fila4/47A.png'"
                  alt="Descripción de la imagen"
                  width="45"
                  height="150"
                  @click="nuevoTratamiento(47)"
                  v-tooltip.top="'Pieza 47A'"
                />
              </td>
              <td>
                <img
                  :src="'https://tiluchisoft.com/clinicadental/dientes/fila4/46A.png'"
                  alt="Descripción de la imagen"
                  width="45"
                  height="150"
                  @click="nuevoTratamiento(46)"
                  v-tooltip.top="'Pieza 46A'"
                />
              </td>
              <td>
                <img
                  :src="'https://tiluchisoft.com/clinicadental/dientes/fila4/45A.png'"
                  alt="Descripción de la imagen"
                  width="45"
                  height="150"
                  @click="nuevoTratamiento(45)"
                  v-tooltip.top="'Pieza 45A'"
                />
              </td>
              <td>
                <img
                  :src="'https://tiluchisoft.com/clinicadental/dientes/fila4/44A.png'"
                  alt="Descripción de la imagen"
                  width="45"
                  height="150"
                  @click="nuevoTratamiento(44)"
                  v-tooltip.top="'Pieza 44A'"
                />
              </td>
              <td>
                <img
                  :src="'https://tiluchisoft.com/clinicadental/dientes/fila4/43A.png'"
                  alt="Descripción de la imagen"
                  width="45"
                  height="150"
                  @click="nuevoTratamiento(43)"
                  v-tooltip.top="'Pieza 43A'"
                />
              </td>
              <td>
                <img
                  :src="'https://tiluchisoft.com/clinicadental/dientes/fila4/42A.png'"
                  alt="Descripción de la imagen"
                  width="45"
                  height="150"
                  @click="nuevoTratamiento(42)"
                  v-tooltip.top="'Pieza 42A'"
                />
              </td>
              <td>
                <img
                  :src="'https://tiluchisoft.com/clinicadental/dientes/fila4/41A.png'"
                  alt="Descripción de la imagen"
                  width="45"
                  height="150"
                  @click="nuevoTratamiento(41)"
                  v-tooltip.top="'Pieza 41A'"
                />
              </td>
              <td>
                <img
                  :src="'https://tiluchisoft.com/clinicadental/dientes/fila4/31A.png'"
                  alt="Descripción de la imagen"
                  width="45"
                  height="150"
                  @click="nuevoTratamiento(31)"
                  v-tooltip.top="'Pieza 31A'"
                />
              </td>
              <td>
                <img
                  :src="'https://tiluchisoft.com/clinicadental/dientes/fila4/32A.png'"
                  alt="Descripción de la imagen"
                  width="45"
                  height="150"
                  @click="nuevoTratamiento(32)"
                  v-tooltip.top="'Pieza 32A'"
                />
              </td>
              <td>
                <img
                  :src="'https://tiluchisoft.com/clinicadental/dientes/fila4/33A.png'"
                  alt="Descripción de la imagen"
                  width="45"
                  height="150"
                  @click="nuevoTratamiento(33)"
                  v-tooltip.top="'Pieza 33A'"
                />
              </td>
              <td>
                <img
                  :src="'https://tiluchisoft.com/clinicadental/dientes/fila4/34A.png'"
                  alt="Descripción de la imagen"
                  width="45"
                  height="150"
                  @click="nuevoTratamiento(34)"
                  v-tooltip.top="'Pieza 34A'"
                />
              </td>
              <td>
                <img
                  :src="'https://tiluchisoft.com/clinicadental/dientes/fila4/35A.png'"
                  alt="Descripción de la imagen"
                  width="45"
                  height="150"
                  @click="nuevoTratamiento(35)"
                  v-tooltip.top="'Pieza 35A'"
                />
              </td>
              <td>
                <img
                  :src="'https://tiluchisoft.com/clinicadental/dientes/fila4/36A.png'"
                  alt="Descripción de la imagen"
                  width="45"
                  height="150"
                  @click="nuevoTratamiento(36)"
                  v-tooltip.top="'Pieza 36A'"
                />
              </td>
              <td>
                <img
                  :src="'https://tiluchisoft.com/clinicadental/dientes/fila4/37A.png'"
                  alt="Descripción de la imagen"
                  width="45"
                  height="150"
                  @click="nuevoTratamiento(37)"
                  v-tooltip.top="'Pieza 37A'"
                />
              </td>
              <td>
                <img
                  :src="'https://tiluchisoft.com/clinicadental/dientes/fila4/38A.png'"
                  alt="Descripción de la imagen"
                  width="45"
                  height="150"
                  @click="nuevoTratamiento(38)"
                  v-tooltip.top="'Pieza 38A'"
                />
              </td>
            </tr>
            <!-- Línea Separadora -->
            <tr>
              <td colspan="16">
                <hr style="border: 0.5px solid #dcdcdc" />
              </td>
            </tr>
            <!-- Fila 5 -->
            <tr>
              <td>
                <img
                  :src="'https://tiluchisoft.com/clinicadental/dientes/fila5/48B.png'"
                  alt="Descripción de la imagen"
                  @click="nuevoTratamiento(48)"
                  v-tooltip.top="'Pieza 48B'"
                />
              </td>
              <td>
                <img
                  :src="'https://tiluchisoft.com/clinicadental/dientes/fila5/47B.png'"
                  alt="Descripción de la imagen"
                  @click="nuevoTratamiento(47)"
                  v-tooltip.top="'Pieza 47B'"
                />
              </td>
              <td>
                <img
                  :src="'https://tiluchisoft.com/clinicadental/dientes/fila5/46B.png'"
                  alt="Descripción de la imagen"
                  @click="nuevoTratamiento(46)"
                  v-tooltip.top="'Pieza 46B'"
                />
              </td>
              <td>
                <img
                  :src="'https://tiluchisoft.com/clinicadental/dientes/fila5/45B.png'"
                  alt="Descripción de la imagen"
                  @click="nuevoTratamiento(45)"
                  v-tooltip.top="'Pieza 45B'"
                />
              </td>
              <td>
                <img
                  :src="'https://tiluchisoft.com/clinicadental/dientes/fila5/44B.png'"
                  alt="Descripción de la imagen"
                  @click="nuevoTratamiento(44)"
                  v-tooltip.top="'Pieza 44B'"
                />
              </td>
            </tr>
            <!-- Línea Separadora -->
            <tr>
              <td colspan="16">
                <hr style="border: 0.5px solid #dcdcdc" />
              </td>
            </tr>
            <tr>
              <td>
                <img
                  :src="'https://tiluchisoft.com/clinicadental/dientes/fila6/48C.png'"
                  alt="Descripción de la imagen"
                  @click="nuevoTratamiento(48)"
                  v-tooltip.top="'Pieza 48C'"
                />
              </td>
              <td>
                <img
                  :src="'https://tiluchisoft.com/clinicadental/dientes/fila6/47C.png'"
                  alt="Descripción de la imagen"
                  @click="nuevoTratamiento(47)"
                  v-tooltip.top="'Pieza 47C'"
                />
              </td>
              <td>
                <img
                  :src="'https://tiluchisoft.com/clinicadental/dientes/fila6/46C.png'"
                  alt="Descripción de la imagen"
                  @click="nuevoTratamiento(46)"
                  v-tooltip.top="'Pieza 46C'"
                />
              </td>
              <td>
                <img
                  :src="'https://tiluchisoft.com/clinicadental/dientes/fila6/45C.png'"
                  alt="Descripción de la imagen"
                  @click="nuevoTratamiento(45)"
                  v-tooltip.top="'Pieza 45C'"
                />
              </td>
              <td>
                <img
                  :src="'https://tiluchisoft.com/clinicadental/dientes/fila6/44C.png'"
                  alt="Descripción de la imagen"
                  @click="nuevoTratamiento(44)"
                  v-tooltip.top="'Pieza 44C'"
                />
              </td>
              <td>
                <img
                  :src="'https://tiluchisoft.com/clinicadental/dientes/fila6/43C.png'"
                  alt="Descripción de la imagen"
                  @click="nuevoTratamiento(43)"
                  v-tooltip.top="'Pieza 43C'"
                />
              </td>
              <td>
                <img
                  :src="'https://tiluchisoft.com/clinicadental/dientes/fila6/42C.png'"
                  alt="Descripción de la imagen"
                  @click="nuevoTratamiento(42)"
                  v-tooltip.top="'Pieza 42C'"
                />
              </td>
              <td>
                <img
                  :src="'https://tiluchisoft.com/clinicadental/dientes/fila6/41C.png'"
                  alt="Descripción de la imagen"
                  @click="nuevoTratamiento(41)"
                  v-tooltip.top="'Pieza 41C'"
                />
              </td>
              <td>
                <img
                  :src="'https://tiluchisoft.com/clinicadental/dientes/fila6/31C.png'"
                  alt="Descripción de la imagen"
                  @click="nuevoTratamiento(31)"
                  v-tooltip.top="'Pieza 31C'"
                />
              </td>
              <td>
                <img
                  :src="'https://tiluchisoft.com/clinicadental/dientes/fila6/32C.png'"
                  alt="Descripción de la imagen"
                  @click="nuevoTratamiento(32)"
                  v-tooltip.top="'Pieza 32C'"
                />
              </td>
              <td>
                <img
                  :src="'https://tiluchisoft.com/clinicadental/dientes/fila6/33C.png'"
                  alt="Descripción de la imagen"
                  @click="nuevoTratamiento(33)"
                  v-tooltip.top="'Pieza 33C'"
                />
              </td>
              <td>
                <img
                  :src="'https://tiluchisoft.com/clinicadental/dientes/fila6/34C.png'"
                  alt="Descripción de la imagen"
                  @click="nuevoTratamiento(34)"
                  v-tooltip.top="'Pieza 34C'"
                />
              </td>
              <td>
                <img
                  :src="'https://tiluchisoft.com/clinicadental/dientes/fila6/35C.png'"
                  alt="Descripción de la imagen"
                  @click="nuevoTratamiento(35)"
                  v-tooltip.top="'Pieza 35C'"
                />
              </td>
              <td>
                <img
                  :src="'https://tiluchisoft.com/clinicadental/dientes/fila6/36C.png'"
                  alt="Descripción de la imagen"
                  @click="nuevoTratamiento(36)"
                  v-tooltip.top="'Pieza 36C'"
                />
              </td>
              <td>
                <img
                  :src="'https://tiluchisoft.com/clinicadental/dientes/fila6/37C.png'"
                  alt="Descripción de la imagen"
                  @click="nuevoTratamiento(37)"
                  v-tooltip.top="'Pieza 37C'"
                />
              </td>
              <td>
                <img
                  :src="'https://tiluchisoft.com/clinicadental/dientes/fila6/38C.png'"
                  alt="Descripción de la imagen"
                  @click="nuevoTratamiento(38)"
                  v-tooltip.top="'Pieza 38C'"
                />
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
    <DataTable
      :value="nuevosTratamientos"
      class="p-datatable-sm"
      responsiveLayout="scroll"
      :rowhover="true"
      stripedRows
      showGridlines
      selectionMode="single click"
    >
      <Column header="#">
        <template #body="slotProps">
          {{ 1 + slotProps.index }}
        </template>
      </Column>
      <Column
        field="tratamiento"
        header="TRATAMIENTO"
        style="font-weight: bold"
      />
      <Column
        field="costo"
        header="COSTO"
        style="text-align: right; font-weight: bold"
      >
        <template #body="slotProps">
          {{ convertirNumeroGermanicFormat(slotProps.data.costo ?? 0) }}
        </template>
      </Column>
      <Column
        field="dientes"
        header="PIEZA"
        style="font-weight: bold; font-size: 14px"
      >
        <template #body="slotProps">
          {{ slotProps.data.dientes }}
        </template>
      </Column>
      <Column field="cara" header="CARA" />
      <Column field="observaciones" header="OBSERVACION" />
      <Column header="ACCIONES">
        <template #body="slotProps">
          <Button
            icon="pi pi-trash"
            class="p-button-rounded p-button-danger p-button-sm"
            @click="eliminarTratamiento(slotProps.rowData)"
          />
        </template>
      </Column>
    </DataTable>
    <div class="flex md:justify-content-end">
      <Button
        label="GUARDAR"
        icon="pi pi-save"
        class="p-button-info p-button-lg"
        @click="guardarPlanTratamiento"
        :disabled="nuevosTratamientos.length === 0"
      />
    </div>

    <NuevoTratamiento
      :show="nuevoTratamientoDialog"
      :piezaSeleccionada="piezaSeleccionada"
      :cliente_id="data_cliente_id"
      :medicos="data_medicos"
      :ubicacion_dental="data_ubicacion_dental"
      @closeModal="ocultarDialog"
      @agregarTratamiento="agregarTratamiento"
    />
  </div>
</template>
<script>
import PlanTratamientoService from "@/service/PlanTratamientoService";
import NuevoTratamiento from "@/module/plantratamientos/NuevoTratamiento.vue";
export default {
  components: {
    NuevoTratamiento,
  },
  props: {
    medicos: {
      type: Array,
      default: () => [],
    },
    cliente_id: {
      type: Number,
      default: 0,
    },
    ubicacion_dental: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      odontograma: [],
      dientes: [
        { id: 1, nombre: "11" },
        { id: 2, nombre: "12" },
        { id: 3, nombre: "13" },
        { id: 4, nombre: "14" },
        { id: 5, nombre: "15" },
        { id: 6, nombre: "16" },
        { id: 7, nombre: "17" },
        { id: 8, nombre: "18" },
        { id: 9, nombre: "21" },
        { id: 10, nombre: "22" },
        { id: 11, nombre: "23" },
        { id: 12, nombre: "24" },
        { id: 13, nombre: "25" },
        { id: 14, nombre: "26" },
        { id: 15, nombre: "27" },
        { id: 16, nombre: "28" },
        { id: 17, nombre: "31" },
        { id: 18, nombre: "32" },
        { id: 19, nombre: "33" },
        { id: 20, nombre: "34" },
        { id: 21, nombre: "35" },
        { id: 22, nombre: "36" },
        { id: 23, nombre: "37" },
        { id: 24, nombre: "38" },
        { id: 25, nombre: "41" },
        { id: 26, nombre: "42" },
        { id: 27, nombre: "43" },
        { id: 28, nombre: "44" },
        { id: 29, nombre: "45" },
        { id: 30, nombre: "46" },
        { id: 31, nombre: "47" },
        { id: 32, nombre: "48" },
      ],
      nuevoTratamientoDialog: false,
      piezaSeleccionada: 0,
      data_medicos: this.medicos || [],
      data_cliente_id: this.cliente_id,
      nuevosTratamientos: [],
      data_ubicacion_dental: this.ubicacion_dental || [],
    };
  },
  planTratamientoService: null,

  created() {
    this.planTratamientoService = new PlanTratamientoService();
  },
  methods: {
    guardarPlanTratamiento() {
      console.log(this.nuevosTratamientos);
    },
    agregarTratamiento(tratamiento) {
      this.nuevosTratamientos.push({
        medico_id: tratamiento.medico_id,
        nombre_medico: tratamiento.nombre_medico,
        fecha_inicio: new Date().toLocaleDateString("es-ES"),
        dientes: tratamiento.dientes,
        cara: tratamiento.cara,
        producto_id: tratamiento.producto_id,
        tratamiento: tratamiento.tratamiento,
        costo: tratamiento.costo,
        descuento: 0,
        acuenta: 0,
        saldo: tratamiento.costo,
        estado: 1,
        observaciones: tratamiento.observaciones,
        medicamentos: tratamiento.medicamentos,
        cubre_seguro: "",
        protesista: 0,
        impuesto: 0,
      });
    },
    eliminarTratamiento(tratamiento) {
      const index = this.nuevosTratamientos.indexOf(tratamiento);
      this.nuevosTratamientos.splice(index, 1);
    },
    nuevoTratamiento(selectedDiente) {
      this.piezaSeleccionada = selectedDiente;
      this.nuevoTratamientoDialog = true;
    },
    ocultarDialog() {
      this.nuevoTratamientoDialog = false;
    },

    getOdontograma() {
      this.planTratamientoService.getOdontograma().then((response) => {
        this.odontograma = response.data;
      });
    },
    convertirNumeroGermanicFormat(numero) {
      return new Intl.NumberFormat("de-DE", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(numero);
    },
  },
  watch: {
    medicos(val) {
      this.data_medicos = val;
    },
    cliente_id(val) {
      this.data_cliente_id = val;
    },
    ubicacion_dental(val) {
      this.data_ubicacion_dental = val;
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/demo/badges.scss";
//ESTILOS A TABVIEW Y TABPANEL QUIERO LETRAS CON NEGRITA Y COLOR AZUL OSCURO
.p-tabview .p-tabview-nav .p-tabview-nav-link {
  font-weight: bold;
  color: #003a6e;
}
.badge {
  margin-left: 5px; /* Margen a la izquierda */
  font-size: 12px; /* Tamaño del texto */
}
.badge-danger {
  color: #dc3545; /* Color de fondo para CARIES */
  font-weight: bold;
}
.badge-info {
  color: #006cc5; /* Color de fondo para RESTAURACIÓN */
  font-weight: bold;
}
.badge-success {
  color: #28a745; /* Color de fondo para TRATAMIENTO */
  font-weight: bold;
}
.badge-warning {
  color: #ffc107; /* Color de fondo para EXTRACCIÓN */
  font-weight: bold;
}

//PARA TODAS LAS IMAGENES HAGO QUE CUANDO PASE EL MOUSE POR ENCIMA SE CAMBIE EL CURSOR Y SE HAGA MÁS GRANDE LA IMAGEN
img {
  cursor: pointer;
  transition: transform 0.2s;
}

img:hover {
  transform: scale(1.3); /* Aumenta el tamaño de la imagen al 110% */
}
</style>
