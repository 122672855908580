import { fetchWrapper } from "@/helpers";

const ruta = process.env.VUE_APP_ROOT_API;

export default class PlanTratamientoService {
  async getPlanTratamientos(datos) {
    const planTratamientos = await fetchWrapper.post(
      `${ruta}/plan_tratamiento/listado`,
      datos
    );
    return planTratamientos;
  }
  async getDataPaciente(id) {
    const paciente = await fetchWrapper.get(
      `${ruta}/plan_tratamiento/datos_paciente/${id}`
    );
    return paciente;
  }

  async guardarPlanTratamiento(planTratamiento) {
    return await fetchWrapper.post(`${ruta}/plan_tratamiento`, planTratamiento);
  }

  async obtenerFormularioConsentimiento(datos) {
    return await fetchWrapper.post(
      `${ruta}/plan_tratamiento/consentimiento`,
      datos
    );
  }

  async obtenerHistoriaClinica(datos) {
    return await fetchWrapper.post(
      `${ruta}/plan_tratamiento/historia_clinica`,
      datos
    );
  }

  async saveHistoriaClinica(datos) {
    return await fetchWrapper.post(
      `${ruta}/plan_tratamiento/guardar_historia_clinica`,
      datos
    );
  }

  async imprimirHistoriaClinica(datos) {
    return await fetchWrapper.postPdf(
      `${ruta}/plan_tratamiento/imprimir_historia_clinica`,
      datos
    );
  }

  async obtenerCuestionarioPersonal(datos) {
    return await fetchWrapper.post(
      `${ruta}/plan_tratamiento/cuestionario_personal`,
      datos
    );
  }

  async eliminarPlanTratamiento(id) {
    return await fetchWrapper.delete(`${ruta}/plan_tratamiento/${id}`);
  }

  async imprimirPlanTratamiento(datos) {
    return await fetchWrapper.postPdf(
      `${ruta}/plan_tratamiento/imprimir_plan`,
      datos
    );
  }
  async imprimirProforma(datos) {
    return await fetchWrapper.postPdf(
      `${ruta}/plan_tratamiento/imprimir_proforma`,
      datos
    );
  }

  async guardarComentario(datos) {
    return await fetchWrapper.post(
      `${ruta}/plan_tratamiento/comentario`,
      datos
    );
  }

  async guardarFormulario(datos) {
    return await fetchWrapper.post(
      `${ruta}/plan_tratamiento/guardar_formulario`,
      datos
    );
  }

  async imprimirFrmConcentimiento(datos) {
    return await fetchWrapper.postPdf(
      `${ruta}/plan_tratamiento/imprimir_consentimiento`,
      datos
    );
  }

  async obtenerTodosLosFormularios(datos) {
    return await fetchWrapper.post(
      `${ruta}/plan_tratamiento/todos_los_formularios`,
      datos
    );
  }

  async imprimirDetalleTransacciones(datos) {
    return await fetchWrapper.postPdf(
      `${ruta}/plan_tratamiento/imprimir_detalle_transacciones`,
      datos
    );
  }

  async guardarPagoPlanTratamiento(datos) {
    return await fetchWrapper.post(
      `${ruta}/plan_tratamiento/pago_plan_tratamiento`,
      datos
    );
  }
}
